<template>
  <div
    class="popup_wrap"
    style="width:800px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.SCHD010P060.001') }}</h1><!-- 지도 -->
      <div class="content_box">
        <div style="height:400px;" class="map_wrap">
          <location-info
            :parent-info="parentInfo"
          />
        </div>
      </div>
      <!-- 닫기 -->
      <div class="mt10 text_center">
        <a
          class="button gray lg"
          href="#"
          @click.prevent="$emit('close')"
        >
          {{ $t('msg.SCHD010P060.002') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import LocationInfo from '@/pages/schedule/LocationInfo'

export default {
  name: 'PortLocationPop',
  components: {
    LocationInfo
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          catCd: 'P',
          portCd: '',
          lttd: '',
          lngtd: ''
        }
      }
     }
  },
  data: function () {
    return {
      // 포트 정보
      portInfo: {}
    }
  },
  created () {},
  methods: {}
}
</script>
